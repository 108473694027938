<template>
  <v-container>
    <print-facture v-if="showShowModal && printer === 'ticket'"/>
    <print-facture-letter v-if="showShowModal && printer === 'letter'"/>
    <v-dialog v-if="buy && showFinishProcess" v-model="showFinishProcess">
      <v-card>
        <v-card-subtitle>
          <div>
            <v-icon>mdi-database-plus</v-icon>
            <span style="text-transform: uppercase;font-weight: bold"> {{
                $vuetify.lang.t('$vuetify.pay.extra_data')
              }} </span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <extra-data :edit="true"
                      :sale="buy"
                      :taxes="localTaxes"
                      :sub-total="parseFloat(buy.subTotalCost).toFixed(2)"
                      :total-price="parseFloat(buy.totalCost).toFixed(2)"
                      :total-discount="parseFloat(buy.totalDiscountCost).toFixed(2)"
                      :total-tax="parseFloat(buy.totalTaxCost).toFixed(2)"
          />
        </v-card-text>
        <action-buttons @hiddenDialog="handlerCloseFinishProcess" :buy="buy"/>
      </v-card>
    </v-dialog>
    <app-data-table
      :id="id"
      :select-data="selectData"
      :headers="getTableColumns"
      csv-filename="SaleProducts"
      :manager="'buy'"
      :items="buys"
      :options="vBindOption"
      :sort-by="['no_facture']"
      :sort-desc="[false, true]"
      :viewShowFilterDate="true"
      multi-sort
      :multi-select=true
      :is-loading="isTableLoading"
      @reload-data="reloadData($event)"
      @create-row="createSaleHandler"
      @edit-row="editSaleHandler($event)"
      @delete-row="deleteBuyHandler($event)"
    >
      <template v-slot:[`item.no_facture`]="{ item }">
        <template>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <b>
                <v-icon
                  style="color: red"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  @click="openPrintModal('ticket',item.id)"
                  v-on="on"
                >
                  mdi-printer
                </v-icon>
              </b>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.report.print_ticket") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <b>
                <v-icon
                  style="color: #1a5dd6"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  @click="openPrintModal('letter', item.id)"
                  v-on="on"
                >
                  mdi-printer-settings
                </v-icon>
              </b>
            </template>
            <span>{{ $vuetify.lang.t("$vuetify.report.print_letter") }}</span>
          </v-tooltip>
        </template>
        {{ item.no_facture }}
      </template>
      <template v-slot:[`item.state_text`]="{ item }">
        <template v-if="item.state !== 'cancelled'">
          <v-autocomplete
            v-model="item.state"
            chips
            :items="getLocalStates(item)"
            item-text="text"
            item-value="value"
            :style="{ width: '160px' }"
            @input="changeState(item)"
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.item.value"
                @click="data.select"
              >
                <i :style="'color: ' + data.item.color">
                  <v-icon left>
                    {{ data.item.icon }}
                  </v-icon>
                  {{ data.item.text }}</i>
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template
                v-if="typeof data.item !== 'object'"
              >
                <v-list-item-content
                  v-text="data.item"
                />
              </template>
              <template v-else>
                <v-list-item-icon>
                  <v-icon
                    left
                    :style="'color: ' + data.item.color"
                  >
                    {{ data.item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    :style="
                          'color: ' + data.item.color
                        "
                  >
                    {{ data.item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </template>
        <span style="color: red" v-else>{{ item.state_text }}</span>
      </template>
      <template
        v-slot:[`item.subTotalCost`]="{ item }"
      >
        {{ parseFloat(item.subTotalCost).toFixed(2) + ' ' + getCurrency }}
      </template>
      <template
        v-slot:[`item.totalCost`]="{ item }"
      >
        <v-tooltip v-if="item.discounts.length > 0 || item.taxes.length > 0 || item.bills.length > 0">
          <template
            v-slot:activator="{ on, attrs }"
          >
            <b>
              <v-icon
                style="color: red"
                class="mr-2"
                small
                v-bind="attrs"
                v-on="on">
                mdi-information
              </v-icon>
            </b>
            <span> {{ parseFloat(item.totalCost).toFixed(2) + ' ' + getCurrency }}</span>
          </template>
          <facture :sale="item" :show-indirect="item.bills.length > 0" :sub-total="parseFloat(item.subTotalCost).toFixed(2)"
                   :total-tax="parseFloat(item.totalTaxCost).toFixed(2)"
                   :total-discount="parseFloat(item.totalDiscountCost).toFixed(2)"
                   :total-price="parseFloat(item.totalCost).toFixed(2)"/>
        </v-tooltip>
        <span v-else> {{ parseFloat(item.totalCost).toFixed(2) + ' ' + getCurrency }}</span>
      </template>
      <template
        v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }"
      >
        <v-btn
          v-if="item.sale_article_shops.length > 0"
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0 0 0 0"
        >
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.firstName") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.variants.cant") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.articles.cost") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.variants.total_cost") }}
                </th>
                <th class="text-left">
                  {{ $vuetify.lang.t("$vuetify.articles.new_inventory") }}
                </th>
                <template v-if="item.state === 'accepted'">
                  <th class="text-left">
                    {{ $vuetify.lang.t("$vuetify.actions.actions") }}
                  </th>
                </template>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="sale_article_shop in item.sale_article_shops"
                :key="sale_article_shop.id"
              >
                <template v-if="sale_article_shop.article_shops">
                  <template>
                    <td v-if="sale_article_shop.article_shops.article">
                      {{ sale_article_shop.article_shops.article.nameComplete }}
                    </td>
                    <td v-else>{{ sale_article_shop.article_shops.article_deleted.nameComplete }}</td>
                  </template>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <b>
                          <v-icon
                            v-if=" sale_article_shop.cantRefund > 0"
                            style="color: red"
                            class="mr-2"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </b>
                      </template>
                      <span>
                      {{
                          $vuetify.lang.t("$vuetify.menu.refund") + ": " + parseFloat(sale_article_shop.cantRefund).toFixed(2)
                        }}
                    </span>
                    </v-tooltip>
                    {{ parseFloat(sale_article_shop.cant).toFixed(2) }}
                    {{ getUM(sale_article_shop) }}
                  </td>
                  <td>
                    <v-tooltip
                      v-if="parseFloat(sale_article_shop.article_shops.cost).toFixed(2) !== parseFloat(sale_article_shop.cost).toFixed(2)"
                      top>
                      <template v-slot:activator="{ on, attrs }">
                          <span
                            v-bind="attrs"
                            v-on="on"
                          >{{ `${parseFloat(sale_article_shop.cost).toFixed(2) + getCurrency}` }}
                          </span>
                      </template>
                      <span>
                        {{
                          $vuetify.lang.t('$vuetify.sale.update_price')
                        }}: {{ `${sale_article_shop.article_shops.cost + ' ' + getCurrency}` }}
                      </span>
                    </v-tooltip>
                    <span v-else>
                      {{ `${sale_article_shop.cost + ' ' + getCurrency}` }}
                    </span>
                  </td>
                  <td>
                    <template
                      v-if="sale_article_shop.article_shops.article.taxes.length > 0 || sale_article_shop.discounts.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <b>
                            <v-icon
                              :color="sale_article_shop.totalRefund > 0 ? 'red': 'primary'"
                              class="mr-2"
                              small
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-information
                            </v-icon>
                          </b>
                        </template>
                        <template>
                          <detail-article-cost
                            :article="sale_article_shop"
                            :currency="user.company.currency"
                          />
                          <span v-if="sale_article_shop.totalRefund > 0">
                                {{
                              $vuetify.lang.t("$vuetify.menu.refund") + ": " +
                              `${sale_article_shop.totalRefund + ' ' + getCurrency}`
                            }}</span>
                        </template>
                      </v-tooltip>
                    </template>
                    <span>{{
                        `${parseFloat(sale_article_shop.totalCost).toFixed(2) + ' ' + getCurrency}`
                      }}</span>
                  </td>
                  <td>
                    <template v-if="sale_article_shop.article_shops.inventory > 0">
                      {{ parseFloat(sale_article_shop.article_shops.inventory).toFixed(2) }} {{
                        getUM(sale_article_shop)
                      }}
                    </template>
                    <template v-else>
                      <i style="color: red">
                        <v-icon
                          style="color: red"
                        >
                          mdi-arrow-down-bold-circle
                        </v-icon>
                        {{ parseFloat(sale_article_shop.article_shops.inventory).toFixed(2) }}
                      </i>
                    </template>
                  </td>
                  <template v-if="item.state === 'accepted'">
                    <td>
                      <template v-if="item.state === 'accepted'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <b>
                              <v-icon
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="refundArticle(item, sale_article_shop)"
                              >
                                mdi-undo
                              </v-icon>
                            </b>
                          </template>
                          <span>{{ $vuetify.lang.t("$vuetify.actions.refund") }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <b>
                              <v-icon
                                v-if="sale_article_shop.cant > 0 "
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="cancelProductPreform(item, sale_article_shop.article_shops)"
                              >
                                mdi-cancel
                              </v-icon>
                            </b>
                          </template>
                          <span>{{ $vuetify.lang.t("$vuetify.actions.refund") }}</span>
                        </v-tooltip>
                      </template>
                    </td>
                  </template>
                </template>
                <template v-else-if="sale_article_shop.article_shops_deleted">
                  <td>
                    <template v-if="sale_article_shop.refunds.length > 0">
                      <v-tooltip right>
                        <template
                          v-slot:activator="{ on, attrs }"
                        >
                          <b>
                            <v-icon
                              v-if=" sale_article_shop.cant > 0 "
                              style="color: red"
                              class="mr-2"
                              small
                              v-bind="attrs"
                              v-on="on">
                              mdi-information
                            </v-icon>
                          </b>
                        </template>
                        <template>
                          <detail-refund
                            :sale-article-shop="sale_article_shop"
                            :currency="`${getCurrency}`"
                          />
                        </template>
                      </v-tooltip>
                    </template>
                    {{
                      sale_article_shop.article_shops_deleted.article_deleted.ref
                    }}({{ $vuetify.lang.t("$vuetify.articles.remove_inventory") }})
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    {{ sale_article_shop.article_shops_deleted.article_deleted.nameComplete }}
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <b>
                          <v-icon
                            v-if=" sale_article_shop.cantRefund > 0"
                            style="color: red"
                            class="mr-2"
                            small
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </b>
                      </template>
                      <span>
                        {{
                          $vuetify.lang.t("$vuetify.menu.refund") + ": " + parseFloat(sale_article_shop.cantRefund).toFixed(2)
                        }}
                      </span>
                    </v-tooltip>
                    {{ parseFloat(sale_article_shop.cant).toFixed(2) }}
                    {{ sale_article_shop.um ? sale_article_shop.um.name : '' }}
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <v-tooltip
                      v-if="parseFloat(sale_article_shop.article_shops_deleted.cost).toFixed(2) !== parseFloat(sale_article_shop.cost).toFixed(2)"
                      top>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                            `${parseFloat(sale_article_shop.cost).toFixed(2) + ' ' + getCurrency}`
                          }} </span>
                      </template>
                      <span>
                        {{ $vuetify.lang.t('$vuetify.sale.update_price') }}: {{
                          `${sale_article_shop.article_shops_deleted.cost + ' ' + getCurrency}`
                        }}
                      </span>
                    </v-tooltip>
                    <span v-else>
                      {{ `${sale_article_shop.cost}` + ' ' + getCurrency }}
                    </span>
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <template
                      v-if="sale_article_shop.article_shops_deleted.article_deleted.taxes.length > 0 || sale_article_shop.discounts.length > 0">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <b>
                            <v-icon
                              :color="sale_article_shop.totalRefund > 0 ? 'red': 'primary'"
                              class="mr-2"
                              small
                              v-bind="attrs"
                              v-on="on">
                              mdi-information
                            </v-icon>
                          </b>
                        </template>
                        <template>
                          <detail-article-price
                            :article="sale_article_shop"
                            :currency="getCurrency"
                          />
                          <span v-if="sale_article_shop.totalRefund > 0">
                            {{
                              $vuetify.lang.t("$vuetify.menu.refund") + ": " +
                              `${sale_article_shop.totalRefund + ' ' + getCurrency}`
                            }}</span>
                        </template>
                      </v-tooltip>
                    </template>
                    <span>{{
                        `${parseFloat(sale_article_shop.totalCost).toFixed(2) + ' ' + getCurrency}`
                      }}</span>
                  </td>
                  <td style="color: red" class="text-decoration-line-through">
                    <template v-if="sale_article_shop.article_shops_deleted.stock > 0">
                      {{ parseFloat(sale_article_shop.article_shops_deleted.stock).toFixed(2) }}
                      {{ sale_article_shop.um ? sale_article_shop.um.name : '' }}
                    </template>
                    <template v-else>
                      <i style="color: red">
                        <v-icon
                          style="color: red"
                        >
                          mdi-arrow-down-bold-circle
                        </v-icon>
                        {{ parseFloat(sale_article_shop.article_shops_deleted.stock).toFixed(2) }}
                      </i>
                    </template>
                  </td>
                  <template v-if="item.state === 'accepted'">
                    <td>
                      <template v-if="item.state === 'accepted'">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <b>
                              <v-icon
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="attrs"
                                v-on="on"
                                @click="refundArticle(item, sale_article_shop)"
                              >
                                mdi-undo
                              </v-icon>
                            </b>
                          </template>
                          <span>{{ $vuetify.lang.t("$vuetify.actions.refund") }}</span>
                        </v-tooltip>
                      </template>
                      <template v-else>
                        <v-tooltip top>
                          <template
                            v-slot:activator="{
                                  on,
                                  attrs
                                }"
                          >
                            <b>
                              <v-icon
                                v-if="
                                    sale_article_shop.cant >
                                      0
                                  "
                                style="color: #ff752b"
                                class="mr-2"
                                small
                                v-bind="
                                    attrs
                                  "
                                v-on="
                                    on
                                  "
                                @click="
                                    cancelProductPreform(
                                      item, sale_article_shop.article_shops
                                    )
                                  "
                              >
                                mdi-cancel
                              </v-icon>
                            </b>
                          </template>
                          <span>{{
                              $vuetify.lang.t(
                                "$vuetify.actions.refund"
                              )
                            }}</span>
                        </v-tooltip>
                      </template>
                    </td>
                  </template>
                </template>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
          <facture v-if="item.discounts.length > 0 || item.taxes.length > 0"
                   :show-facture="false"
                   :sale="item"
                   :sub-total="item.subTotalCost.toString()"
                   :total-discount="item.totalDiscountCost.toString()"
                   :total-tax="item.totalTaxCost.toString()"
                   :total-price="item.totalCost.toString()"
                   :show-indirect="true"
          />
        </td>
      </template>
    </app-data-table>
  </v-container>
</template>
<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import Facture from '../../facture/Facture'
import DetailArticleCost from './list-buy/DetailArticleCost'
import PrintFacture from './PrintFacture'
import PrintFactureLetter from './PrintFactureLetter'
import ExtraData from '../../buy/commerce/manger-buy/ExtraData'
import ActionButtons from './manger-buy/ActionButtons'

export default {
  name: 'ListSale',
  components: { ActionButtons, ExtraData, PrintFactureLetter, PrintFacture, Facture, DetailArticleCost },
  data () {
    return {
      showFinishProcess: false,
      buy: null,
      filter: [],
      saleId: null,
      showDeleteMessage: false,
      tab: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.titles.list', [
          this.$vuetify.lang.t('$vuetify.menu.vending')
        ])
      }],
      selectData: [],
      loadingData: false,
      id: 'Sale',
      menu: false,
      fav: true,
      message: false,
      hints: true,
      search: '',
      localAccess: {},
      printer: '',
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: true
      },
      localTaxes: []
    }
  },
  computed: {
    ...mapState('buy', ['editBuy', 'showNewModal', 'showEditModal', 'loadData', 'showShowModal', 'buys', 'isTableLoading']),
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapGetters('auth', ['isManagerIn']),
    ...mapState('tax', ['taxes']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.tax.noFacture'),
          value: 'no_facture',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.state'),
          value: 'state_text',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.cost'),
          value: 'subTotalCost',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.total_price'),
          value: 'totalCost',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shop.name',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    this.switchLoadData(false)
    await this.getTaxes()
  },
  methods: {
    ...mapActions('buy', ['toogleNewModal', 'openEditModal', 'openShowModal', 'updateBuy',
      'changeStateBuy', 'switchLoadData', 'deleteBuy', 'multiDelete', 'getBuysFilters']),
    ...mapActions('tax', ['getTaxes']),
    updateLocalTaxes () {
      this.localTaxes = this.taxes
      if (this.buy.sale_article_shops.length > 0) {
        this.buy.sale_article_shops.forEach((artS) => {
          artS.sale_article_taxes.forEach((v) => {
            this.localTaxes = this.localTaxes.filter(t => t.id !== v.tax_id)
            this.buy.taxes = this.buy.taxes.filter(t => t.id !== v.tax_id)
          })
        })
      }
    },
    getUM (item) {
      return (item.article_shops.article.um.name)
    },
    handlerCloseFinishProcess () {
      this.showFinishProcess = false
      // window.location.reload()
    },
    getLocalStates (item) {
      return item.state === 'open'
        ? [
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.open'
            ),
            value: 'open',
            icon: 'mdi-star-half',
            color: '#4caf50'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.cancelled'
            ),
            value: 'cancelled',
            icon: 'mdi-star-off',
            color: '#ff0000'
          }
        ]
        : item.state === 'preform' ? [
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.preform'
            ),
            value: 'preform',
            icon: 'mdi-star-half',
            color: '#3f51b5'
          },
          {
            text: this.$vuetify.lang.t('$vuetify.sale.state.open'),
            value: 'open',
            icon: 'mdi-star',
            color: '#4caf50'
          },
          {
            text: this.$vuetify.lang.t(
              '$vuetify.sale.state.cancelled'
            ),
            value: 'cancelled',
            icon: 'mdi-star-off',
            color: '#ff0000'
          }
        ]
          : [
            {
              text: this.$vuetify.lang.t(
                '$vuetify.sale.state.accepted'
              ),
              value: 'accepted',
              icon: 'mdi-star',
              color: '#3f51b5'
            },
            {
              text: this.$vuetify.lang.t(
                '$vuetify.sale.state.cancelled'
              ),
              value: 'cancelled',
              icon: 'mdi-star-off',
              color: '#ff0000'
            }
          ]
    },
    async changeState (item) {
      if (item.state === 'open') {
        this.buy = item
        this.updateLocalTaxes()
        item.state = 'preform'
        item.state_text = this.$vuetify.lang.t('$vuetify.sale.state.preform')
        this.showFinishProcess = true
      } else {
        await this.changeStateBuy({ id: item.id, state: item.state })
        window.location.reload()
      }
    },
    openPrintModal (print, id) {
      this.printer = print
      this.openShowModal(id)
    },
    reloadData (filter) {
      this.filter = filter
      this.filter[2] = 'preform'
      this.loadInitData()
    },
    async loadInitData () {
      await this.getBuysFilters(this.filter)
      this.switchLoadData(false)
      // this.loadingData = false
    },
    createSaleHandler () {
      this.$router.push({ name: 'buy_add' })
    },
    editSaleHandler ($event) {
      this.$router.push({ name: 'buy_edit', params: { hash: btoa($event) } })
    },
    deleteBuyHandler ($event) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.buy')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ) + '. ' + this.$vuetify.lang.t(
            '$vuetify.messages.restart_articles'
          ),
          icon: 'question',
          showCancelButton: true,
          showDenyButton: true,
          cancelDenyText: this.$vuetify.lang.t(
            '$vuetify.actions.no'
          ),
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.yes'
          )
        })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteBuy({ id: $event, restart: true }).then(() => {
              this.loadInitData()
              // window.location.reload()
            })
          }
          if (result.isDenied) {
            this.deleteBuy({ id: $event, restart: false }).then(() => {
              this.loadInitData()
              // window.location.reload()
            })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
